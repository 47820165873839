
import $ph from '@/plugins/phoenix';
import { IGlossaryItem } from '@/plugins/phoenix/glossaries';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ListStateChip extends Vue {
  // Mode:
  // icon - Iconic representation (default)
  // chip - Code as chip representation
  // icon-label - Icon and label representation
  // chip-label - Label as chip representation
  @Prop() readonly mode: any;

  @Prop() readonly value: any;
  @Prop() readonly list: any;
  @Prop() readonly loading: any;
  @Prop() readonly XSmall: any;
  @Prop() readonly small: any;
  @Prop() readonly large: any;
  @Prop() readonly XLarge: any;
  @Prop() readonly left: any;
  @Prop() readonly top: any;
  @Prop() readonly right: any;
  @Prop() readonly bottom: any;

  get showIcon(): boolean {
    return this.mode === null || this.mode === undefined || this.mode === '' || this.mode === 'icon' || this.mode === 'icon-label';
  }

  get showChip(): boolean {
    return this.mode === 'chip' || this.mode === 'chip-label';
  }

  get showLabel(): boolean {
    return this.mode === 'icon-label' || this.mode === 'chip-label';
  }

  get title(): string {
    return $ph.i18n(this.item.label);
  }

  get color(): string | null {
    return this.item.color;
  }

  get textColor(): string | null {
    return this.item.textColor;
  }

  get icon(): string | null {
    return this.item.icon;
  }

  get item(): IGlossaryItem<string> {
    return $ph.item(this.list, this.value);
  }
}
