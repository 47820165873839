
import $ph from '@/plugins/phoenix'
import $moment from 'moment'
import { NumberFormat } from '@/plugins/phoenix/formats';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { FieldModes, FieldStyles } from '../types';
import settings from '@/plugins/settings';

@Component
export default class DataField extends Vue {
  @Prop(Boolean) readonly alignCenter: boolean | undefined;
  @Prop(Boolean) readonly alignRight: boolean | undefined;
  @Prop(String) readonly errorText: string | undefined;
  @Prop(String) readonly extraClass: string | undefined;
  @Prop() readonly mode: FieldModes | undefined;
  @Prop() readonly fieldStyle: FieldStyles | undefined;
  @Prop(String) readonly format: string | undefined;
  @Prop(String) readonly regexp: string | undefined;
  @Prop(Boolean) readonly randomId: boolean | undefined;
  @Prop(Boolean) readonly required: boolean | undefined;
  @Prop(Boolean) readonly stringifyDates: boolean | undefined;
  @Prop(String) readonly forSearch: string | undefined;
  @Prop(String) readonly utc: string | undefined;
  @Prop() readonly copy: any;
  @Prop() readonly copyValue: any;
  // ---------------------------------------------------------------------------- Parent props
  @Prop() readonly appendIcon: any;
  @Prop() readonly appendOuterIcon: any;
  @Prop() readonly autocomplete: any;
  @Prop() readonly autoGrow: any;
  @Prop(Boolean) readonly autofocus: boolean | undefined;
  @Prop() readonly backgroundColor: any;
  @Prop() readonly clearIcon: any;
  @Prop(Boolean) readonly clearable: boolean | undefined;
  @Prop() readonly color: any;
  @Prop(Boolean) readonly counter: boolean | undefined;
  @Prop() readonly dark: any;
  @Prop() readonly dense: any;
  @Prop(Boolean) readonly disabled: boolean | undefined;
  @Prop(Boolean) readonly error: boolean | undefined;
  @Prop({ type: Number, default: 1 }) readonly errorCount: number | undefined;
  @Prop() readonly errorMessages: any;
  @Prop(Boolean) readonly flat: boolean | undefined;
  @Prop(Boolean) readonly fullWidth: boolean | undefined;
  @Prop() readonly height: any;
  @Prop(Boolean) readonly hideDetails: boolean | undefined;
  @Prop() readonly hint: any;
  @Prop() readonly id: any;
  @Prop() readonly label: any;
  @Prop() readonly light: any;
  @Prop() readonly loaderHeight: any;
  @Prop(Boolean) readonly loading: boolean | undefined;
  @Prop() readonly maxlength: any;
  @Prop() readonly messages: any;
  @Prop() readonly noResize: any;
  @Prop(Boolean) readonly persistentHint: boolean | undefined;
  @Prop(Boolean) readonly persistentPlaceholder: boolean | undefined;
  @Prop() readonly placeholder: any;
  @Prop() readonly prefix: any;
  @Prop() readonly prependIcon: any;
  @Prop() readonly prependInnerIcon: any;
  @Prop(Boolean) readonly readonly: boolean | undefined;
  @Prop(Boolean) readonly reverse: boolean | undefined;
  @Prop() readonly rounded: any;
  @Prop() readonly rules: any;
  @Prop() readonly shaped: any;
  @Prop(Boolean) readonly singleLine: boolean | undefined;
  @Prop() readonly success: any;
  @Prop() readonly successMessages: any;
  @Prop() readonly suffix: any;
  @Prop() readonly type: any;
  @Prop(Boolean) readonly validateOnBlur: boolean | undefined;
  @Prop() readonly value: any;

  decimal = 0
  numFormat: NumberFormat | null = null
  internalError: string | null = null
  datePicker = false
  focused = false
  rndid = ''

  @Watch('format', { immediate: true })
  async onFormatChange(value: string | null) {
    if (this.mode === FieldModes.numeric && value && value !== '') {
      let fmt = value
      let gsym = fmt.substring(0, 1)
      fmt = fmt.substring(1)
      let glen = ''
      for (let i = 0; i < fmt.length; i++) {
        let sym = fmt.charAt(i)
        if (isNaN(Number(sym))) {
          fmt = fmt.substring(i)
          break
        }
        glen = glen + sym
      }

      let dsym = fmt.substring(0, 1)
      let dlen = fmt.substring(1)

      this.decimal = dlen === '' ? 0 : Number(dlen)
      this.numFormat = new NumberFormat(
        dsym,
        glen === '' ? 99 : Number(glen),
        gsym,
      )
    }
  }

  doCopy(evt: any) {
    if (this.copyValue) {
      this.$copyText(this.copyValue)
    } else {
      $ph.copyInput(evt)
    }
  }

  doAction(event: any) {
    this.$emit('action', event)
  }

  doClear(event: any) {
    this.$emit('clear', event)
  }

  clickAppendOuter(event: any) {
    this.$emit('click:append-outer', event)
  }

  clickAppend(event: any) {
    this.$emit('click:append', event)
  }

  clickPrependInner(event: any) {
    this.$emit('click:prepend-inner', event)
  }

  clickPrepend(event: any) {
    this.$emit('click:prepend', event)
  }

  onfocus(event: any) {
    this.focused = true
    this.$emit('focus', event)
  }

  onblur(event: any) {
    if (this.mode === FieldModes.numeric) {
      const s = new NumberFormat('.', 9999999999999999, ' ').format(Number(this.value), this.decimal)
      const v = Number(s);
      if (!isNaN(v)) {
        this.$emit('input', v);
      }
    }

    this.focused = false
    this.$emit('blur', event)
  }

  formatNumber(v: any): string {
    return this.numFormat
      ? this.numFormat.format(Number(this.value), this.decimal)
      : $ph.format(Number(this.value), { dec: this.decimal })
  }

  validate() {
    if (!this.value || this.value === '') {
      this.internalError = this.required
        ? $ph.i18n('globals.RequiredField')
        : null
    } else {
      if (this.mode === FieldModes.numeric) {
        let tmp = Number(String(this.value).replace(',', '.'))
        if (isNaN(tmp)) {
          this.internalError = this.errorText
            ? $ph.i18n(this.errorText)
            : $ph.i18n('globals.InvalidNumber')
        } else {
          this.internalError = null
        }
      } else if (
        this.mode === FieldModes.date ||
        this.mode === FieldModes.datepicker ||
        this.mode === FieldModes.calendar
      ) {
        let m = $moment(this.value, 'YYYY-MM-DD', true)
        if (!m.isValid()) {
          this.internalError = this.errorText
            ? $ph.i18n(this.errorText)
            : $ph.i18n('globals.InvalidDate')
        } else {
          this.internalError = null
        }
      } else {
        if (this.regexp && this.regexp !== '') {
          const pattern = new RegExp(this.regexp, 'ui')
          this.internalError = pattern.test(this.value) ? null : (
            this.errorText
              ? $ph.i18n(this.errorText)
              : $ph.i18n('globals.InvalidField')
          )
        } else {
          this.internalError = null
        }
      }
    }
  }

  get env() {
    return process.env
  }

  get classes() {
    let result = 'data-control ';

    if (this.alignCenter) {
      result += 'text-center center-control '
    } else if (this.alignRight || this.mode === FieldModes.numeric) {
      result += 'text-right right-control '
    }

    if (this.extraClass) {
      result += this.extraClass + ' '
    }

    return result;
  }

  bool(value: any): boolean {
    return value === '' || String(value) === 'true';
  }

  get inputBack() {
    if (this.backgroundColor && this.backgroundColor !== '') {
      return this.backgroundColor;
    } else if (this.forSearch === 'true' || this.forSearch === '') {
      return 'search-back'
    } else {
      return 'input-back'
    }
  }

  get appInputStyle(): any {
    return this.forSearch === 'true' || this.forSearch === ''
      ? settings.company.companyUISettings.search.class || FieldStyles.regular
      : settings.company.companyUISettings.input.class || FieldStyles.regular
  }

  get appInputDense(): boolean {
    return this.forSearch === 'true' || this.forSearch === ''
      ? settings.company.companyUISettings.search.dense
      : settings.company.companyUISettings.input.dense
  }

  get appInputRounded(): boolean {
    return this.forSearch === 'true' || this.forSearch === ''
      ? settings.company.companyUISettings.search.rounded !== 'no'
      : settings.company.companyUISettings.input.rounded !== 'no'
  }

  get appInputShaped(): boolean {
    return this.forSearch === 'true' || this.forSearch === ''
      ? settings.company.companyUISettings.search.shaped
      : settings.company.companyUISettings.input.shaped
  }

  get isDense(): boolean {
    return this.dense === null || this.dense === undefined ? this.appInputDense : this.bool(this.dense);
  }

  get isRounded(): boolean {
    return this.rounded === null || this.rounded === undefined ? this.appInputRounded : this.bool(this.rounded);
  }

  get isShaped(): boolean {
    return this.shaped === null || this.shaped === undefined ? this.appInputShaped : this.bool(this.shaped);
  }

  get isOutline(): boolean {
    return this.fieldStyle
      ? this.fieldStyle === FieldStyles.outlined
      : this.appInputStyle === FieldStyles.outlined;
  }

  get isSolo(): boolean {
    return this.fieldStyle
      ? this.fieldStyle === FieldStyles.solo
      : this.appInputStyle === FieldStyles.solo;
  }

  get isSoloInverted(): boolean {
    return this.fieldStyle
      ? this.fieldStyle === FieldStyles.inverted
      : this.appInputStyle === FieldStyles.inverted;
  }

  get isFilled(): boolean {
    return this.fieldStyle
      ? this.fieldStyle === FieldStyles.filled
      : this.appInputStyle === FieldStyles.filled;
  }

  get isError(): boolean {
    return this.error === null || this.error === undefined ? this.internalError !== null : this.bool(this.error);
  }

  get _errorMessages() {
    return this.errorMessages === null || this.errorMessages === undefined || this.errorMessages.length === 0
      ? (this.internalError !== null ? [this.internalError] : [])
      : this.errorMessages;
  }

  get caption() {
    if (this.required && this.label && this.label !== '') {
      return $ph.i18n(this.label) + ' *'
    } else {
      return $ph.i18n(this.label)
    }
  }

  get placeholderText() {
    return $ph.i18n(this.placeholder)
  }

  get isUTC() {
    return this.utc || this.utc === '';
  }

  get source(): string {
    if (this.mode === 'datetime') {
      let m = this.isUTC ? $moment.utc(this.value, 'YYYY-MM-DDTHH:mm:ss', true) : $moment(this.value, 'YYYY-MM-DDTHH:mm:ss', true);
      if (this.value !== undefined && this.value !== null && m.isValid()) {
        return m.format('YYYY-MM-DDTHH:mm:ss')
      } else {
        m = this.isUTC ? $moment.utc(new Date(), 'YYYY-MM-DDTHH:mm:ss', true) : $moment(new Date(), 'YYYY-MM-DDTHH:mm:ss', true)
        return m.format('YYYY-MM-DDTHH:mm:ss')
      }
    } else {
      let m = this.isUTC ? $moment.utc(this.value, 'YYYY-MM-DD', true) : $moment(this.value, 'YYYY-MM-DD', true)
      if (this.value !== undefined && this.value !== null && m.isValid()) {
        return m.format('YYYY-MM-DD')
      } else {
        m = this.isUTC ? $moment.utc(new Date(), 'YYYY-MM-DD', true) : $moment(new Date(), 'YYYY-MM-DD', true)
        return m.format('YYYY-MM-DD')
      }
    }
  }

  set source(value: string) {
    if (this.mode === 'datetime') {
      let m = this.isUTC ? $moment.utc(value, 'YYYY-MM-DDTHH:mm:ss', true) : $moment(value, 'YYYY-MM-DDTHH:mm:ss', true)
      if (this.stringifyDates) {
        this.$emit('input', m.format('YYYY-MM-DDTHH:mm:ss'))
      } else {
        this.$emit('input', m.toDate())
      }
    } else {
      let m = this.isUTC ? $moment.utc(value, 'YYYY-MM-DD', true) : $moment(value, 'YYYY-MM-DD', true)
      if (this.stringifyDates) {
        this.$emit('input', m.format('YYYY-MM-DD'))
      } else {
        this.$emit('input', m.toDate())
      }
    }
  }

  get variable() {
    if (this.mode === 'number' && this.focused && this.value === 0) {
      return ''
    }

    if (this.value && this.value !== '') {
      if (this.mode === 'number') {
        let tmp = Number(String(this.value).replace(',', '.'))
        if (!isNaN(tmp)) {
          if (!this.focused || this.readonly) {
            return this.formatNumber(Number(this.value));
          } else {
            return tmp.normalize();
          }
        }
      } else if (
        this.mode === FieldModes.date ||
        this.mode === FieldModes.datepicker ||
        this.mode === FieldModes.calendar
      ) {
        let m = this.isUTC ? $moment.utc(this.value, 'YYYY-MM-DD', true) : $moment(this.value, 'YYYY-MM-DD', true)
        if (m.isValid()) {
          return m.format(this.format)
        }
      } else if (this.mode === FieldModes.datetime) {
        let m = this.isUTC
          ? $moment.utc(this.value, 'YYYY-MM-DDTHH:mm:ss', true)
          : $moment(this.value, 'YYYY-MM-DDTHH:mm:ss', true)
        if (m.isValid()) {
          return m.format(this.format)
        }
      } else {
        return decodeURIComponent(this.value ? this.value.replace('%', '%25') : this.value)
      }
    }
    return this.value
  }

  set variable(value) {
    if (!value && value !== '') {
      if (
        this.mode === FieldModes.numeric ||
        this.mode === FieldModes.date ||
        this.mode === FieldModes.datepicker ||
        this.mode === FieldModes.calendar
      ) {
        value = null
      }
    } else {
      if (this.mode === FieldModes.numeric) {
        let tmp = Number(String(value).replace(',', '.'))
        if (!isNaN(tmp)) {
          value = Number(tmp)
        }
      } else if (this.mode === FieldModes.datetime) {
        let m = this.isUTC ? $moment.utc(value, this.format, true) : $moment(value, this.format, true)
        if (m.isValid()) {
          if (this.stringifyDates) {
            value = m.format('YYYY-MM-DDTHH:mm:ss')
          } else {
            value = m.toDate()
          }
        }
      } else if (
        this.mode === FieldModes.date ||
        this.mode === FieldModes.datepicker ||
        this.mode === FieldModes.calendar
      ) {
        let m = this.isUTC ? $moment.utc(value, this.format, true) : $moment(value, this.format, true)
        if (m.isValid()) {
          if (this.stringifyDates) {
            value = m.format('YYYY-MM-DD')
          } else {
            value = m.toDate()
          }
        }
      }
    }

    this.$emit('input', value)
  }

  beforeMount() {
    this.rndid = 'fld_' + new Date().getTime() + '-' + Math.random()
    this.validate()
  }

  beforeUpdate() {
    this.validate()
  }
}
