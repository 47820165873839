
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class CheckChip extends Vue {
  @Prop() readonly value: any;
  @Prop() readonly caption: any;

  get color(): string {
    if (this.value) {
      return 'success';
    } else {
      return 'disabled';
    }
  }

  get icon(): string {
    if (this.value) {
      return 'fa-check-square';
    } else {
      return 'far fa-square';
    }
  }

  click() {
    this.$emit('click')
  }
}
