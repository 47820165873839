
import $ph from '@/plugins/phoenix'
import settings from '@/plugins/settings';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { CodeModes, CodeSyles, FieldStyles, LabelStyles } from '../types';

@Component
export default class DataSelect extends Vue {
  @Prop() readonly fieldStyle: FieldStyles | undefined;
  @Prop(String) readonly errorText: string | undefined;
  @Prop(String) readonly extraClass: string | undefined;
  @Prop(Boolean) readonly required: boolean | undefined;
  @Prop({ default: CodeModes.both }) readonly codeMode: CodeModes | undefined;
  @Prop({ default: CodeSyles.chip }) readonly codeStyle: CodeSyles | undefined;
  @Prop({ default: LabelStyles.both }) readonly labelStyle: LabelStyles | undefined;
  @Prop(String) readonly forSearch: string | undefined;
  // ---------------------------------------------------------------------------- Parent props
  @Prop() readonly appendIcon: any;
  @Prop() readonly appendOuterIcon: any;
  @Prop() readonly autoGrow: any;
  @Prop(Boolean) readonly autofocus: boolean | undefined;
  @Prop() readonly backgroundColor: any;
  @Prop() readonly clearIcon: any;
  @Prop(Boolean) readonly clearable: boolean | undefined;
  @Prop() readonly color: any;
  @Prop(Boolean) readonly counter: boolean | undefined;
  @Prop() readonly dark: any;
  @Prop() readonly dense: any;
  @Prop(Boolean) readonly disabled: boolean | undefined;
  @Prop(Boolean) readonly error: boolean | undefined;
  @Prop({ type: Number, default: 1 }) readonly errorCount: number | undefined;
  @Prop() readonly errorMessages: any;
  @Prop(Boolean) readonly flat: boolean | undefined;
  @Prop(Boolean) readonly fullWidth: boolean | undefined;
  @Prop() readonly height: any;
  @Prop(Boolean) readonly hideDetails: boolean | undefined;
  @Prop() readonly hint: any;
  @Prop() readonly items: any;
  @Prop() readonly itemValue: any;
  @Prop() readonly itemText: any;
  @Prop() readonly label: any;
  @Prop() readonly light: any;
  @Prop() readonly loaderHeight: any;
  @Prop(Boolean) readonly loading: boolean | undefined;
  @Prop() readonly maxlength: any;
  @Prop() readonly messages: any;
  @Prop() readonly noResize: any;
  @Prop(Boolean) readonly persistentHint: boolean | undefined;
  @Prop(Boolean) readonly persistentPlaceholder: boolean | undefined;
  @Prop() readonly placeholder: any;
  @Prop() readonly prefix: any;
  @Prop() readonly prependIcon: any;
  @Prop() readonly prependInnerIcon: any;
  @Prop(Boolean) readonly readonly: boolean | undefined;
  @Prop(Boolean) readonly reverse: boolean | undefined;
  @Prop() readonly rounded: any;
  @Prop() readonly rules: any;
  @Prop() readonly shaped: any;
  @Prop(Boolean) readonly singleLine: boolean | undefined;
  @Prop() readonly success: any;
  @Prop() readonly successMessages: any;
  @Prop() readonly suffix: any;
  @Prop() readonly type: any;
  @Prop(Boolean) readonly validateOnBlur: boolean | undefined;
  @Prop() readonly value: any;

  focused = false

  doClear(event: any) {
    this.$emit('clear', event)
  }

  doAction(event: any) {
    this.$emit('action', event)
  }

  doChange(event: any) {
    this.$emit('change', event)
  }

  clickAppendOuter(event: any) {
    this.$emit('click:append-outer', event)
  }

  clickAppend(event: any) {
    this.$emit('click:append', event)
  }

  clickPrependInner(event: any) {
    this.$emit('click:prepend-inner', event)
  }

  clickPrepend(event: any) {
    this.$emit('click:prepend', event)
  }

  removeItem(item: any) {
    this.$emit('remove', item)
  }

  onfocus() {
    this.focused = true
  }

  onblur() {
    this.focused = false
  }

  get inputBack() {
    if (this.backgroundColor && this.backgroundColor !== '') {
      return this.backgroundColor;
    } else if (this.forSearch === 'true' || this.forSearch === '') {
      return 'search-back'
    } else {
      return 'input-back'
    }
  }

  get appInputStyle(): any {
    return this.forSearch === 'true' || this.forSearch === ''
      ? settings.company.companyUISettings.search.class || FieldStyles.regular
      : settings.company.companyUISettings.input.class || FieldStyles.regular
  }

  get appInputDense(): boolean {
    return this.forSearch === 'true' || this.forSearch === ''
      ? settings.company.companyUISettings.search.dense
      : settings.company.companyUISettings.input.dense
  }

  get appInputRounded(): boolean {
    return this.forSearch === 'true' || this.forSearch === ''
      ? settings.company.companyUISettings.search.rounded !== 'no'
      : settings.company.companyUISettings.input.rounded !== 'no'
  }

  get appInputShaped(): boolean {
    return this.forSearch === 'true' || this.forSearch === ''
      ? settings.company.companyUISettings.search.shaped
      : settings.company.companyUISettings.input.shaped
  }

  get classes() {
    let result = 'data-control ';

    if (this.extraClass) {
      result += this.extraClass + ' '
    }

    return result;
  }

  bool(value: any): boolean {
    return value === '' || String(value) === 'true';
  }

  get isDense(): boolean {
    return this.dense === null || this.dense === undefined ? this.appInputDense : this.bool(this.dense);
  }

  get isRounded(): boolean {
    return this.rounded === null || this.rounded === undefined ? this.appInputRounded : this.bool(this.rounded);
  }

  get isShaped(): boolean {
    return this.shaped === null || this.shaped === undefined ? this.appInputShaped : this.bool(this.shaped);
  }

  get isOutline(): boolean {
    return this.fieldStyle
      ? this.fieldStyle === FieldStyles.outlined
      : this.appInputStyle === FieldStyles.outlined;
  }

  get isSolo(): boolean {
    return this.fieldStyle
      ? this.fieldStyle === FieldStyles.solo
      : this.appInputStyle === FieldStyles.solo;
  }

  get isSoloInverted(): boolean {
    return this.fieldStyle
      ? this.fieldStyle === FieldStyles.inverted
      : this.appInputStyle === FieldStyles.inverted;
  }

  get isFilled(): boolean {
    return this.fieldStyle
      ? this.fieldStyle === FieldStyles.filled
      : this.appInputStyle === FieldStyles.filled;
  }

  get isError(): boolean {
    return this.error === null || this.error === undefined ? false : this.bool(this.error);
  }

  get _errorMessages() {
    return this.errorMessages === null || this.errorMessages === undefined || this.errorMessages.length === 0
      ? ((!this.value || this.value === '') && this.required ? $ph.i18n('globals.RequiredField') : null)
      : this.errorMessages;
  }

  get caption() {
    if (this.required && this.label && this.label !== '') {
      return $ph.i18n(this.label) + ' *'
    } else {
      return $ph.i18n(this.label)
    }
  }

  get placeholderText() {
    return $ph.i18n(this.placeholder)
  }

  get variable() {
    return this.value
  }

  set variable(value) {
    this.$emit('input', value)
  }
}
